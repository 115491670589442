@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'NeueHaasGroteskBold';
  src: url('./fonts/NHaasGroteskDSPro-65Md.woff2') format('woff2'),
       url('./fonts/NHaasGroteskDSPro-65Md.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasGroteskRegular';
  src: url('./fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2'),
       url('./fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NeueHaasGroteskThin';
  src: url('./fonts/NHaasGroteskDSPro-35XLt.woff2') format('woff2'),
       url('./fonts/NHaasGroteskDSPro-35XLt.woff2') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'NeueHaasGroteskRegular', sans-serif;
}

h1 {
  color:black;
  font-family: 'NeueHaasGroteskBold', sans-serif;
}

.linkbutton{
  font-family: "NeueHaasGroteskBold";
  /* text-white */
  color: white;

  /* bg-blue-900 */
  background-color: #404040;  /* This color is an approximation, actual color might vary based on your Tailwind configuration */

  /* hover:bg-blue-800 */
  &:hover {
      background-color: #525252;  /* This color is an approximation, actual color might vary based on your Tailwind configuration */
  }

  /* inline-flex */
  display: inline-flex;

  /* items-center */
  align-items: center;

  /* justify-center */
  justify-content: center;

  /* w-full */
  width: 100%;

  /* px-6 */
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  /* py-3 */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  /* my-4 */
  margin-top: 1rem;
  margin-bottom: 1rem;

  /* text-lg */
  font-size: 1.125rem;

  /* shadow-xl */
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  /* rounded-2xl */
  border-radius: 1rem;
}

/* sm:w-auto */
@media (min-width: 640px) {
  .linkbutton {
      width: auto;
  }
}

/* sm:mb-0 */
@media (min-width: 640px) {
  .linkbutton {
      margin-bottom: 0;
  }
}


.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
  font-family: "NeueHaasGroteskBold";
  color: black;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}


